import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Config } from "../models/config.interface";
@Injectable({providedIn: 'root'})
export class ConfigService {
    // configFile = 'assets/config.json'; 
    configFile : Config = new Config(); 
    constructor(private http: HttpClient){ 
        
    }

    // getConfig() : Observable<Config> {
    //     return this.http.get<Config>(this.configFile); 
    // } 
    
    getConfig() : Observable<Config> {
        return of(this.configFile);
    }
}