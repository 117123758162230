import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute, Router } from "@angular/router";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { SplashScreenService } from "../@vex/services/splash-screen.service";
import { VexConfigName } from "../@vex/config/config-name.model";
import { ColorSchemeName } from "../@vex/config/colorSchemeName";
import {
  MatIconRegistry,
  SafeResourceUrlWithIconOptions,
} from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  ColorVariable,
  colorVariables,
} from "../@vex/components/config-panel/color-variables";
import { ApplicationUser } from "./models/application-user";
import { AuthService } from "./services/auth.service";

@Component({
  selector: "vex-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  user!: ApplicationUser;

  constructor(
    private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private authService: AuthService,
    private router: Router
  ) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case "mat":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case "logo":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case "flag":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe((queryParamMap) => {
      if (queryParamMap.has("layout")) {
        this.configService.setConfig(
          queryParamMap.get("layout") as VexConfigName
        );
      }

      if (queryParamMap.has("style")) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get("style") as ColorSchemeName,
          },
        });
      }

      if (queryParamMap.has("primaryColor")) {
        const color: ColorVariable =
          colorVariables[queryParamMap.get("primaryColor")];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color,
              },
            },
          });
        }
      }

      if (queryParamMap.has("rtl")) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get("rtl"))
            ? "rtl"
            : "ltr",
        });
      }
    });
  }

  ngOnInit() {
    this.authService.user$.subscribe((user) => {
      // debugger
      this.user = user;

      console.log("user", this.user);

      if (this.user === null || this.user === undefined) {
        this.router.navigate(["login"]);
      }

      /**
       * Add your own routes here
       */
      this.navigationService.items = [
        {
          type: "subheading",
          label: "Pages",
          children: [
            // {
            //   type: 'link',
            //   label: 'Analytics',
            //   route: '/',
            //   icon: 'mat:insights',
            //   routerLinkActiveOptions: { exact: true }
            // },
            {
              type: "link",
              label: "Übersicht",
              route: "/",
              icon: "mat:dashboard",
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: "link",
              label: "Kunden",
              route: "/clients",
              icon: "mat:person",
              routerLinkActiveOptions: { exact: true },
            },
            {
              type: "link",
              label: "Aufträge",
              route: "/baustelle",
              icon: "mat:handyman",
              routerLinkActiveOptions: { exact: true },
            },

            {
              type: "link",
              label: "Hersteller",
              route: "/ankersensor",
              icon: "mat:settings",
              routerLinkActiveOptions: { exact: true },
            },
          ],
        },
      ];

      if (this.user?.role === "Administrator") {
        this.navigationService.items = [
          ...this.navigationService.items,
          {
            type: "subheading",
            label: "Administration",
            children: [
              {
                type: "link",
                label: "Benutzern",
                route: "/users",
                icon: "mat:people",
                routerLinkActiveOptions: { exact: true },
              },
            ],
          },
          {
            type: "link",
            label: "Organization",
            route: "/organizations",
            icon: "mat:corporate_fare",
            routerLinkActiveOptions: { exact: true },
          },
        ];
      }
    });
  }
}
