import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { UsersComponent } from "./users/users.component";
import { UserCreateComponent } from "./users/user-create/user-create.component";
import { UserEditComponent } from "./users/user-edit/user-edit.component";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./auth/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "",
    component: CustomLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./calendar/calendar.module").then((m) => m.CalendarModule),
      },
      {
        path: "clients",
        loadChildren: () =>
          import("./client-list/client.module").then((m) => m.ClientsModule),
      },
      {
        path: "ankersensor",
        loadChildren: () =>
          import("./anker-list/anker.module").then((m) => m.AnkerModule),
      },
      {
        path: "organizations",
        loadChildren: () =>
          import("./organization-list/organization.module").then(
            (m) => m.OrganizationListModule
          ),
      },
      {
        path: "baustelle",
        loadChildren: () =>
          import("./baustelle-list/baustelle.module").then(
            (m) => m.BaustelleListModule
          ),
      },
      {
        path: "users",
        loadChildren: () =>
          import("./users/users.module").then((m) => m.UsersModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule, QuicklinkModule],
})
export class AppRoutingModule { }
