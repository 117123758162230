import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ConfigService } from '../services/config.service';
const authorizedServices = [
  'employee',
]
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
   private apiUrl = ''; 
  constructor(private authService: AuthService, private configService : ConfigService) {
    this.configService.getConfig().subscribe(config=>{
        this.apiUrl = `${config.apiUrl}/account`;
    })
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // add JWT auth header if a user is logged in for API requests
    const accessToken = localStorage.getItem('access_token');
    // const isApiUrl = request.url.startsWith(this.apiUrl);
    if (accessToken // && isApiUrl
        ) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${accessToken}` },
      });
    }

    return next.handle(request);
  }
}
