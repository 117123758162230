import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ConfigService } from '../services/config.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    private apiUrl = ''; 
  constructor(private authService: AuthService, private router: Router, private configService: ConfigService) {
    this.configService.getConfig().subscribe(conf=>{
        this.apiUrl = `${conf.apiUrl}/account`;
    })
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.authService.clearLocalStorage();
            this.router.navigate(['login'], {
              queryParams: { returnUrl: this.router.routerState.snapshot.url },
            });
        }

        if (!this.apiUrl.includes('localhost')) {
          console.error(err);
        }
        const error = (err && err.error && err.error.message) || err.statusText;
        return throwError(error);
      })
    );
  }
}
