<h2 mat-dialog-title>Sind Sie sicher, dass?</h2>
<mat-dialog-content> Möchten Sie eine E-Mail senden? </mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="onNoClick()">Cancel</button>
  <button
    mat-button
    mat-dialog-close
    cdkFocusInitial
    class="bg-green-500 text-white"
    (click)="onYesClick()"
  >
    Send
  </button>
</mat-dialog-actions>
